import { createStore, select, withProps } from '@ngneat/elf';
import {
  AddressCheckConfigurationDto,
  AddressCheckFeedbackDto,
  AddressCheckInstitutionTeacherDto,
  LotteryDto,
  ReadSchoolDataDto,
  SchoolDataValidationErrorDto,
  TeacherStaffDto,
  TeacherStaffValidationErrorDto,
} from '@verona/address-check-public-api';
import {
  SchoolDataValidationErrorKey,
  TeacherStaffValidationErrorKey,
} from './address-check.definitions';
import { Observable } from 'rxjs';

export interface LotteryExtension {
  selected: boolean;
  shouldDisplayError?: boolean;
}

interface StoreLoadingProps {
  configuration: boolean;
  schoolData: boolean;
  teacherStaff: boolean;
  teachers: boolean;
  editSchoolData: boolean;
  lottery: boolean;
}

interface StoreProps {
  currentStep: string;
  configuration: AddressCheckConfigurationDto;

  schoolData: ReadSchoolDataDto;
  schoolDataValidationErrors: SchoolDataValidationErrorDto[];
  schoolDataEditMode: boolean;
  teacherStaff: TeacherStaffDto;
  originalTeachers: Array<AddressCheckInstitutionTeacherDto>;
  teacherStaffValidationErrors: TeacherStaffValidationErrorDto[];
  teacherStaffEditMode: boolean;
  lottery: LotteryDto & LotteryExtension;
  lotteryEditMode: boolean;
  feedback: AddressCheckFeedbackDto;
  loading: StoreLoadingProps;
}

const addressCheckPublicStore = createStore(
  { name: 'address-check-public-store' },
  withProps<StoreProps>({
    currentStep: undefined as unknown as string,
    configuration: {} as AddressCheckConfigurationDto,

    schoolData: {} as ReadSchoolDataDto,
    schoolDataValidationErrors: [],
    schoolDataEditMode: false,
    teacherStaff: {} as TeacherStaffDto,
    originalTeachers: [],
    teacherStaffValidationErrors: [],
    teacherStaffEditMode: false,
    lottery: {} as LotteryDto & LotteryExtension,
    lotteryEditMode: false,
    feedback: {} as AddressCheckFeedbackDto,
    loading: {
      configuration: true,
      schoolData: true,
      teacherStaff: true,
      teachers: false,
      editSchoolData: false,
      lottery: false,
    },
  })
);

export function updateLoading(loading: Partial<StoreLoadingProps>) {
  addressCheckPublicStore.update((state) => {
    return {
      ...state,
      loading: {
        ...state.loading,
        ...loading,
      },
    };
  });
}

export const configurationLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.configuration)
);

export const updateConfiguration = (
  newConfiguration: AddressCheckConfigurationDto
) => {
  addressCheckPublicStore.update((state) => ({
    ...state,
    ...{ configuration: newConfiguration },
  }));
};

export const configuration$ = addressCheckPublicStore.pipe(
  select((state) => state.configuration)
);

export const currentStep$ = addressCheckPublicStore.pipe(
  select((state) => state.currentStep)
);

export function setCurrentStep(step: string) {
  addressCheckPublicStore.update((state) => {
    return {
      ...state,
      currentStep: step,
    };
  });
}

export const lotteryLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.lottery)
);

export const lottery$ = addressCheckPublicStore.pipe(
  select((state) => state.lottery)
);

export const updateLottery = (newLottery: LotteryDto & LotteryExtension) => {
  if (!(newLottery.selected ?? true)) {
    delete newLottery.lotteryParticipantId;
    delete newLottery.lotteryParticipantName;
    delete newLottery.lotteryParticipantEmail;
  }

  addressCheckPublicStore.update((state) => ({
    ...state,
    lottery: { ...newLottery },
  }));
};

export const lotteryEditMode$ = addressCheckPublicStore.pipe(
  select((state) => state.schoolDataEditMode)
);

export const updateLotteryEditMode = (editMode: boolean) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    lotteryEditMode: editMode,
  }));

export const hasLotteryErrors$ = addressCheckPublicStore.pipe(
  select((state) => {
    return (
      state.lottery?.selected &&
      state.lottery?.lotteryParticipantId === undefined
    );
  })
);

export const schoolData$ = addressCheckPublicStore.pipe(
  select((state) => state.schoolData)
);

export const setSchoolData = (newSchoolData: Partial<ReadSchoolDataDto>) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    schoolData: {
      ...state.schoolData,
      ...newSchoolData,
    },
  }));

export const schoolDataLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.schoolData)
);

export const editSchoolDataLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.editSchoolData)
);

export const hasSchoolDataValidationErrors$ = addressCheckPublicStore.pipe(
  select((state) => 0 < state.schoolDataValidationErrors.length)
);

export function schoolDataValidationErrors$(
  fieldName: SchoolDataValidationErrorKey
) {
  return addressCheckPublicStore.pipe(
    select((state) =>
      state.schoolDataValidationErrors.find(
        (error) => fieldName === error.fieldName
      )
    )
  ) as Observable<SchoolDataValidationErrorDto>;
}

export function removeSchoolDataValidationError(
  fieldName: SchoolDataValidationErrorKey
) {
  addressCheckPublicStore.update((state) => ({
    ...state,
    schoolDataValidationErrors: removeElementByIndex(
      state.schoolDataValidationErrors,
      state.schoolDataValidationErrors.findIndex(
        (error) => fieldName === error.fieldName
      )
    ),
  }));
}

export const setSchoolDataValidationErrors = (
  schoolDataValidationErrors: SchoolDataValidationErrorDto[]
) => {
  addressCheckPublicStore.update((state) => ({
    ...state,
    schoolDataValidationErrors: schoolDataValidationErrors ?? null,
  }));
};

export const teacherStaffLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.teacherStaff)
);

export const teachersLoading$ = addressCheckPublicStore.pipe(
  select(({ loading }) => loading.teachers)
);

export const teacherStaff$ = addressCheckPublicStore.pipe(
  select((state) => state.teacherStaff)
);

export const originalTeachers$ = addressCheckPublicStore.pipe(
  select((state) => state.originalTeachers)
);

export const headmaster$ = addressCheckPublicStore.pipe(
  select((state) => state.teacherStaff.headmaster)
);

export const schoolBookManagers$ = addressCheckPublicStore.pipe(
  select((state) => state.teacherStaff.schoolBookManagers)
);

export const teachers$ = addressCheckPublicStore.pipe(
  select((state) => state.teacherStaff.teachers)
);

export const updateTeacherStaff = (teacherStaff: Partial<TeacherStaffDto>) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    teacherStaff: {
      ...state.teacherStaff,
      ...teacherStaff,
    },
  }));

export const updateTeachers = (
  teachers: Array<AddressCheckInstitutionTeacherDto>
) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    teacherStaff: {
      ...state.teacherStaff,
      teachers: teachers,
    },
  }));

export const updateOriginalTeachers = (
  teachers: Array<AddressCheckInstitutionTeacherDto>
) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    originalTeachers: teachers,
  }));

export function teacherStaffValidationErrors$(
  fieldName: TeacherStaffValidationErrorKey
) {
  return addressCheckPublicStore.pipe(
    select((state) =>
      state.teacherStaffValidationErrors.find(
        (error) => fieldName === error.fieldName
      )
    )
  ) as Observable<TeacherStaffValidationErrorDto>;
}

export function removeTeacherStaffValidationError(
  fieldName: TeacherStaffValidationErrorKey
) {
  addressCheckPublicStore.update((state) => ({
    ...state,
    teacherStaffValidationErrors: removeElementByIndex(
      state.teacherStaffValidationErrors ?? [],
      (state.teacherStaffValidationErrors ?? []).findIndex(
        (error) => fieldName === error.fieldName
      )
    ),
  }));
}

export const setTeacherStaffValidationErrors = (
  teacherStaffValidationErrors: TeacherStaffValidationErrorDto[]
) => {
  addressCheckPublicStore.update((state) => ({
    ...state,
    teacherStaffValidationErrors: teacherStaffValidationErrors,
  }));
};

export const feedback$ = addressCheckPublicStore.pipe(
  select((state) => state.feedback)
);

export const setFeedback = (feedback: AddressCheckFeedbackDto) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    feedback,
  }));

export const editMode$ = addressCheckPublicStore.pipe(
  select(
    (state) =>
      state.schoolDataEditMode ||
      state.teacherStaffEditMode ||
      state.lotteryEditMode
  )
);

export const schoolDataEditMode$ = addressCheckPublicStore.pipe(
  select((state) => state.schoolDataEditMode)
);

export const updateSchoolDataEditMode = (editMode: boolean) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    schoolDataEditMode: editMode,
  }));

export const teacherStaffEditMode$ = addressCheckPublicStore.pipe(
  select((state) => state.teacherStaffEditMode)
);

export const updateTeacherStaffEditMode = (editMode: boolean) =>
  addressCheckPublicStore.update((state) => ({
    ...state,
    teacherStaffEditMode: editMode,
  }));

function removeElementByIndex<T>(selected: T[], selectedIndex: number): T[] {
  const newSelected: T[] = [...selected];
  if (selectedIndex >= 0) {
    newSelected.splice(selectedIndex, 1);
  }
  return newSelected;
}
