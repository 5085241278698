/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface AddressCheckConfigurationDto
 */
export interface AddressCheckConfigurationDto {
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  id?: string;
  /**
   *
   * @type {number}
   * @memberof AddressCheckConfigurationDto
   */
  atLeastPastMonthsAfterLastAddressCheck?: number;
  /**
   *
   * @type {boolean}
   * @memberof AddressCheckConfigurationDto
   */
  lotteryEnabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof AddressCheckConfigurationDto
   */
  gdprNoticeEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  welcomePageBody?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  welcomePageMotivator?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolDataPageBody?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolDataPageMotivatorTitle?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolDataPageMotivatorLabel?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolDataPageMotivatorNotice?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherListPageBody?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherListPageGdprNotice?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherInvitationPageBody?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherInvitationPageGdprNotice?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  completionPageBody?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolMailSubject?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolMailPreviewText?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolMailContent?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  schoolMailPostScript?: string | null;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherMailSubject?: string;
  /**
   *
   * @type {string}
   * @memberof AddressCheckConfigurationDto
   */
  teacherMailContent?: string;
}

/**
 * Check if a given object implements the AddressCheckConfigurationDto interface.
 */
export function instanceOfAddressCheckConfigurationDto(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function AddressCheckConfigurationDtoFromJSON(
  json: any
): AddressCheckConfigurationDto {
  return AddressCheckConfigurationDtoFromJSONTyped(json, false);
}

export function AddressCheckConfigurationDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): AddressCheckConfigurationDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: !exists(json, 'id') ? undefined : json['id'],
    atLeastPastMonthsAfterLastAddressCheck: !exists(
      json,
      'atLeastPastMonthsAfterLastAddressCheck'
    )
      ? undefined
      : json['atLeastPastMonthsAfterLastAddressCheck'],
    lotteryEnabled: !exists(json, 'lotteryEnabled')
      ? undefined
      : json['lotteryEnabled'],
    gdprNoticeEnabled: !exists(json, 'gdprNoticeEnabled')
      ? undefined
      : json['gdprNoticeEnabled'],
    welcomePageBody: !exists(json, 'welcomePageBody')
      ? undefined
      : json['welcomePageBody'],
    welcomePageMotivator: !exists(json, 'welcomePageMotivator')
      ? undefined
      : json['welcomePageMotivator'],
    schoolDataPageBody: !exists(json, 'schoolDataPageBody')
      ? undefined
      : json['schoolDataPageBody'],
    schoolDataPageMotivatorTitle: !exists(json, 'schoolDataPageMotivatorTitle')
      ? undefined
      : json['schoolDataPageMotivatorTitle'],
    schoolDataPageMotivatorLabel: !exists(json, 'schoolDataPageMotivatorLabel')
      ? undefined
      : json['schoolDataPageMotivatorLabel'],
    schoolDataPageMotivatorNotice: !exists(
      json,
      'schoolDataPageMotivatorNotice'
    )
      ? undefined
      : json['schoolDataPageMotivatorNotice'],
    teacherListPageBody: !exists(json, 'teacherListPageBody')
      ? undefined
      : json['teacherListPageBody'],
    teacherListPageGdprNotice: !exists(json, 'teacherListPageGdprNotice')
      ? undefined
      : json['teacherListPageGdprNotice'],
    teacherInvitationPageBody: !exists(json, 'teacherInvitationPageBody')
      ? undefined
      : json['teacherInvitationPageBody'],
    teacherInvitationPageGdprNotice: !exists(
      json,
      'teacherInvitationPageGdprNotice'
    )
      ? undefined
      : json['teacherInvitationPageGdprNotice'],
    completionPageBody: !exists(json, 'completionPageBody')
      ? undefined
      : json['completionPageBody'],
    schoolMailSubject: !exists(json, 'schoolMailSubject')
      ? undefined
      : json['schoolMailSubject'],
    schoolMailPreviewText: !exists(json, 'schoolMailPreviewText')
      ? undefined
      : json['schoolMailPreviewText'],
    schoolMailContent: !exists(json, 'schoolMailContent')
      ? undefined
      : json['schoolMailContent'],
    schoolMailPostScript: !exists(json, 'schoolMailPostScript')
      ? undefined
      : json['schoolMailPostScript'],
    teacherMailSubject: !exists(json, 'teacherMailSubject')
      ? undefined
      : json['teacherMailSubject'],
    teacherMailContent: !exists(json, 'teacherMailContent')
      ? undefined
      : json['teacherMailContent'],
  };
}

export function AddressCheckConfigurationDtoToJSON(
  value?: AddressCheckConfigurationDto | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    atLeastPastMonthsAfterLastAddressCheck:
      value.atLeastPastMonthsAfterLastAddressCheck,
    lotteryEnabled: value.lotteryEnabled,
    gdprNoticeEnabled: value.gdprNoticeEnabled,
    welcomePageBody: value.welcomePageBody,
    welcomePageMotivator: value.welcomePageMotivator,
    schoolDataPageBody: value.schoolDataPageBody,
    schoolDataPageMotivatorTitle: value.schoolDataPageMotivatorTitle,
    schoolDataPageMotivatorLabel: value.schoolDataPageMotivatorLabel,
    schoolDataPageMotivatorNotice: value.schoolDataPageMotivatorNotice,
    teacherListPageBody: value.teacherListPageBody,
    teacherListPageGdprNotice: value.teacherListPageGdprNotice,
    teacherInvitationPageBody: value.teacherInvitationPageBody,
    teacherInvitationPageGdprNotice: value.teacherInvitationPageGdprNotice,
    completionPageBody: value.completionPageBody,
    schoolMailSubject: value.schoolMailSubject,
    schoolMailPreviewText: value.schoolMailPreviewText,
    schoolMailContent: value.schoolMailContent,
    schoolMailPostScript: value.schoolMailPostScript,
    teacherMailSubject: value.teacherMailSubject,
    teacherMailContent: value.teacherMailContent,
  };
}
