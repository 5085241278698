import React, { FC } from 'react';
import { SelectProps } from '@mui/material/Select/Select';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  inputLabelClasses,
  Skeleton,
} from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { IsoCountryCodeEnum } from '@verona/address-check-public-api';

type IsoCountrySelectProps = SelectProps<IsoCountryCodeEnum> & {
  isoCountries: IsoCountryCodeEnum[];
  loading?: boolean;
  helperText?: string;
};

export const IsoCountrySelect: FC<IsoCountrySelectProps> = (props) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.schoolData',
  });

  const { isoCountries, loading, helperText, ...selectProps } = props;

  return loading ? (
    <Skeleton variant={'rounded'} sx={{ height: 53 }} />
  ) : (
    <FormControl fullWidth>
      <InputLabel
        id="iso-country-input-label"
        disabled={props.disabled}
        sx={{
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.focused}`]: {
              color: (theme) => theme.palette.text.primary,
            },
          },
        }}
      >
        {props.label}
      </InputLabel>
      <Select<IsoCountryCodeEnum>
        labelId="iso-country-input-label"
        id="iso-country-input"
        label={props.label}
        variant={'outlined'}
        {...selectProps}
      >
        {isoCountries.map((country) => (
          <MenuItem value={country} key={country}>
            {t(`country.options.${country}.label`)}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};
