import { createAction, createEffect, ofType } from '@ngneat/effects';
import { apiConfiguration } from '../../lib';
import { debugAction } from '@verona/utils';
import { tap } from 'rxjs';
import { SelectOptionsApi } from '@verona/address-check-public-api';
import { addErrorNotification } from '@verona/notifications';
import {
  updateIsoCountries,
  updateSelectOptionsLoading,
} from './select-options.repository';

export const loadIsoCountries = createAction(
  '[Addresscheck Public] Load IsoCountry codes'
);

export const loadIsoCountries$ = createEffect((actions) => {
  return actions.pipe(
    ofType(loadIsoCountries),
    debugAction(),
    tap(() => updateSelectOptionsLoading({ isoCountries: true })),
    tap(() =>
      new SelectOptionsApi(apiConfiguration)
        .getIsoCountryCodes()
        .then(updateIsoCountries)
        .catch(() => addErrorNotification('address-check-public:error'))
        .finally(() => updateSelectOptionsLoading({ isoCountries: false }))
    )
  );
});
