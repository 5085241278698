import { createStore, select, withProps } from '@ngneat/elf';
import { IsoCountryCodeEnum } from '@verona/address-check-public-api';

interface IsoCountryProps {
  isoCountries: IsoCountryCodeEnum[];
}

interface LoadingProps {
  isoCountries: boolean;
}

interface StoreProps {
  selectOptions: IsoCountryProps;
  loading: LoadingProps;
}

const selectOptionsStore = createStore(
  { name: 'select-options-store' },
  withProps<StoreProps>({
    selectOptions: {
      isoCountries: [],
    },
    loading: {
      isoCountries: true,
    },
  })
);

export const isoCountries$ = selectOptionsStore.pipe(
  select(({ selectOptions }) => selectOptions.isoCountries)
);

export const updateIsoCountries = (
  newCountries: IsoCountryProps['isoCountries']
) => {
  selectOptionsStore.update((state) => ({
    ...state,
    selectOptions: {
      ...state.selectOptions,
      isoCountries: newCountries,
    },
  }));
};

export const isoCountriesLoading$ = selectOptionsStore.pipe(
  select(({ loading }) => loading.isoCountries)
);

export function updateSelectOptionsLoading(loading: Partial<LoadingProps>) {
  selectOptionsStore.update((state) => ({
    ...state,
    loading: {
      ...state.loading,
      ...loading,
    },
  }));
}
