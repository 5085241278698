import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { AddressCheckStepProps } from './step-props';
import { Article, EmailRounded, ExpandMore, Send } from '@mui/icons-material';
import Gmail from './../../assets/gmail_logo.png';
import Outlook from './../../assets/outlook_logo.png';
import { CopyableContainer, LoadingSkeleton } from '@verona/components';
import sanitize from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import { AddressCheckConfigurationDto } from '@verona/address-check-public-api';

export const TeacherInvitationPage: FC<AddressCheckStepProps> = ({
  config,
  configLoading,
  isMobile,
}) => {
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [openEmailHelpAccordion, setOpenEmailHelpAccordion] = useState(false);

  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherInvitation',
  });

  return (
    <>
      <LoadingSkeleton loading={configLoading}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box
            id={'danger'}
            dangerouslySetInnerHTML={{
              __html: sanitize(config.teacherInvitationPageBody ?? ''),
            }}
          />
          {config.gdprNoticeEnabled && (
            <Box
              dangerouslySetInnerHTML={{
                __html: sanitize(config.teacherInvitationPageGdprNotice ?? ''),
              }}
            />
          )}
        </Box>
      </LoadingSkeleton>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Button
          sx={{
            height: '50px',
            width: isMobile ? '40%' : '25%',
            mx: 'auto',
            mt: 5,
          }}
          variant="contained"
          endIcon={<Send />}
          onClick={(event) => {
            setIsEmailDialogOpen(!isEmailDialogOpen);
          }}
        >
          <Typography variant={'h4'}>{t('sendEmailButton.label')}</Typography>
        </Button>
        <OpenEmailClientDialog
          open={isEmailDialogOpen}
          onClose={() => {
            setIsEmailDialogOpen(false);
            setOpenEmailHelpAccordion(true);
          }}
          emailSubject={encodeURIComponent(
            config.teacherMailSubject?.toString() ?? ''
          )}
          emailBody={encodeURIComponent(
            config.teacherMailContent?.toString() ?? ''
          )}
        />
        <CopyEmailContent
          isMobile={isMobile}
          config={config}
          openAccordion={openEmailHelpAccordion}
        />
      </Box>
    </>
  );
};

export const CopyEmailContent: FC<{
  isMobile: boolean;
  config: AddressCheckConfigurationDto;
  openAccordion?: boolean;
}> = ({ isMobile, config, openAccordion }) => {
  const theme = useTheme();
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherInvitation',
  });

  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    openAccordion && setExpanded(true);
  }, [openAccordion]);

  return (
    <Accordion
      expanded={expanded}
      elevation={0}
      sx={{
        '&::before': {
          display: 'none',
        },
        border: 0,
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.light,
        borderRadius: 1,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        onClick={() => setExpanded(!expanded)}
      >
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Article color={'primary'}></Article>
            <Typography variant={'h3'}>{t('manualEmailHint.label')}</Typography>
          </Box>
          <Typography variant={'h4'} sx={{ m: 1 }}>
            {t('manualEmailHint.info')}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 4,
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              position: 'relative',
            }}
          >
            <CopyableContainer
              copyValue={config.teacherMailSubject ?? ''}
              notificationLabel={'address-check-public:notifications'}
              label={t('subject.label')}
              hoverLabel={t('copyHover.label')}
            >
              <Box sx={{ whiteSpace: 'pre-wrap' }}>
                {config.teacherMailSubject}
              </Box>
            </CopyableContainer>
          </Box>

          <Box
            sx={{
              width: '100%',
              height: 'fit-content',
              position: 'relative',
            }}
          >
            <CopyableContainer
              copyValue={config.teacherMailContent ?? ''}
              notificationLabel={'address-check-public:notifications'}
              label={t('content.label')}
              hoverLabel={t('copyHover.label')}
            >
              <Box sx={{ whiteSpace: 'pre-wrap' }}>
                {config.teacherMailContent}
              </Box>
            </CopyableContainer>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export interface OpenEmailClientDialogProps {
  open: boolean;
  selectedValue?: string;
  onClose: (value: string) => void;
  emailSubject: string;
  emailBody: string;
}

export interface EmailClient {
  name: string;
  openHref: string;
  icon: ReactElement;
}

export const OpenEmailClientDialog: FC<OpenEmailClientDialogProps> = ({
  open,
  selectedValue,
  onClose,
  emailSubject,
  emailBody,
}) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.teacherInvitation',
  });
  const emailClients: EmailClient[] = [
    {
      name: t('emailClients.gmail.label'),
      openHref: `https://mail.google.com/mail/?view=cm&fs=1&su=${emailSubject}&body=${emailBody}`,
      icon: <Box component={'img'} src={Gmail} sx={{ width: '100%' }} />,
    },
    {
      name: t('emailClients.outlook.label'),
      openHref: `https://outlook.office.com/owa/?path=/mail/action/compose&subject=${emailSubject}&body=${emailBody}`,
      icon: <Box component={'img'} src={Outlook} sx={{ width: '70%' }} />,
    },
    {
      name: t('emailClients.default.label'),
      openHref: `mailto:?to=&subject=${emailSubject}&cc=&bcc=&body=${emailBody}`,
      icon: <EmailRounded sx={{ transform: 'scale(1.3)' }} />,
    },
  ];

  const theme = useTheme();
  const handleClose = () => {
    onClose('selectedValue');
  };

  const handleListItemClick = (link: string) => {
    window.open(link, '_blank', 'noreferrer');
    onClose(link);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle sx={{ display: 'flex', flexDirection: 'column' }}>
        {t('chooseEmailClient.label')}
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        {emailClients.map((email) => (
          <ListItem disableGutters key={email.name}>
            <ListItemButton onClick={() => handleListItemClick(email.openHref)}>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: 'white', color: theme.palette.primary.main }}
                >
                  {email.icon}
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={email.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};
