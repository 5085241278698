/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 */
export const IsoCountryCodeEnum = {
  AT: 'AT',
  IT: 'IT',
  CH: 'CH',
  DE: 'DE',
} as const;
export type IsoCountryCodeEnum =
  (typeof IsoCountryCodeEnum)[keyof typeof IsoCountryCodeEnum];

export function IsoCountryCodeEnumFromJSON(json: any): IsoCountryCodeEnum {
  return IsoCountryCodeEnumFromJSONTyped(json, false);
}

export function IsoCountryCodeEnumFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): IsoCountryCodeEnum {
  return json as IsoCountryCodeEnum;
}

export function IsoCountryCodeEnumToJSON(
  value?: IsoCountryCodeEnum | null
): any {
  return value as any;
}
