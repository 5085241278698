import React, { FC, useEffect, useState } from 'react';
import { useObservable } from '@ngneat/react-rxjs';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import sanitize from 'sanitize-html';
import { useTranslation } from 'react-i18next';
import {
  editSchoolDataLoading$,
  hasSchoolDataValidationErrors$,
  loadLotteryData,
  loadSchoolData,
  removeSchoolDataValidationErrorAction,
  schoolData$,
  schoolDataEditMode$,
  schoolDataLoading$,
  SchoolDataValidationErrorKey,
  schoolDataValidationErrors$,
  setSchoolDataEditMode,
  setSchoolDataValidationErrors,
  updateSchoolData,
} from '../../store';
import { dispatch } from '@ngneat/effects';
import { AddressCheckStepProps } from './step-props';
import { DeleteForever, Edit, Save } from '@mui/icons-material';
import {
  IsoCountryCodeEnum,
  ReadSchoolDataDto,
  UpdateSchoolDataDto,
} from '@verona/address-check-public-api';
import { LoadingButton } from '@mui/lab';
import {
  LoadingSkeleton,
  PhoneNumberInput,
  TextFieldWithLoading,
} from '@verona/components';
import { IsoCountrySelect } from '../iso-country-select/iso-country-select';
import {
  isoCountries$,
  isoCountriesLoading$,
  loadIsoCountries,
} from '../../store/select-options';

export const SchoolDataPage: FC<AddressCheckStepProps> = ({
  isActive,
  isMobile,
  config,
  configLoading,
  addressCheckInstitutionId,
}) => {
  useEffect(() => {
    isActive &&
      dispatch(
        loadLotteryData({ addressCheckInstitutionId }),
        loadSchoolData({ addressCheckInstitutionId }),
        loadIsoCountries()
      );
  }, [isActive]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <LoadingSkeleton loading={configLoading}>
        <Box
          dangerouslySetInnerHTML={{
            __html: sanitize(config.schoolDataPageBody ?? ''),
          }}
        />
      </LoadingSkeleton>

      <Divider sx={{ opacity: 0, m: isMobile ? 0 : 3 }} />

      <SchoolDataForm
        addressCheckInstitutionId={addressCheckInstitutionId}
        isMobile={isMobile}
      />
    </Box>
  );
};

export const SchoolDataForm: FC<{
  addressCheckInstitutionId: string;
  isMobile?: boolean;
}> = ({ addressCheckInstitutionId, isMobile }) => {
  const { t } = useTranslation('address-check-public', {
    keyPrefix: 'steps.schoolData',
  });

  const [originalSchoolData] = useObservable(schoolData$);
  const [schoolDataLoading] = useObservable(schoolDataLoading$);

  const [editMode] = useObservable(schoolDataEditMode$);
  const [editSchoolDataLoading] = useObservable(editSchoolDataLoading$);

  const [countries] = useObservable(isoCountries$);
  const [countriesLoading] = useObservable(isoCountriesLoading$);

  useEffect(() => {
    setSchoolData(originalSchoolData);
  }, [originalSchoolData]);

  const [schoolData, setSchoolData] = useState(originalSchoolData);

  const [postalCodeError] = useObservable(
    schoolDataValidationErrors$('postalCode')
  );
  const [cityError] = useObservable(schoolDataValidationErrors$('city'));
  const [streetError] = useObservable(schoolDataValidationErrors$('street'));
  const [streetNumberError] = useObservable(
    schoolDataValidationErrors$('streetNumber')
  );
  const [countryError] = useObservable(schoolDataValidationErrors$('country'));
  const [phoneError] = useObservable(schoolDataValidationErrors$('phone'));
  const [emailError] = useObservable(schoolDataValidationErrors$('email'));
  const [homePageError] = useObservable(
    schoolDataValidationErrors$('homepage')
  );

  const [hasErrors] = useObservable(hasSchoolDataValidationErrors$);

  const handleSchoolDataChange = (newSchoolData: ReadSchoolDataDto) => {
    let key: keyof ReadSchoolDataDto;
    for (key in newSchoolData) {
      if (newSchoolData[key] !== schoolData[key]) {
        dispatch(
          removeSchoolDataValidationErrorAction({
            fieldName: key.toString() as SchoolDataValidationErrorKey,
          })
        );
      }
    }
    setSchoolData(newSchoolData);
  };

  const discardChanges = () => {
    setSchoolData(originalSchoolData);
    setSchoolDataValidationErrors([]);
    dispatch(setSchoolDataEditMode({ editMode: false }));
  };

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex',
          width: '100%',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <Typography variant={'h2'}>{t('label')}</Typography>

        {!editMode ? (
          <Button
            disabled={schoolDataLoading}
            variant={'contained'}
            startIcon={<Edit />}
            onClick={() => {
              dispatch(setSchoolDataEditMode({ editMode: true }));
            }}
          >
            {t('edit.start.label')}
          </Button>
        ) : (
          <Box sx={{ display: 'inline-flex', gap: 2 }}>
            <Button
              color={'error'}
              startIcon={<DeleteForever />}
              disabled={editSchoolDataLoading}
              onClick={() => {
                discardChanges();
              }}
            >
              {isMobile
                ? t(`edit.discard.short.label`)
                : t(`edit.discard.label`)}
            </Button>

            <LoadingButton
              type={'submit'}
              variant={'contained'}
              disabled={schoolDataLoading || hasErrors}
              loading={editSchoolDataLoading}
              loadingPosition={'start'}
              sx={{ maxWidth: '100%' }}
              startIcon={<Save />}
              onClick={() => {
                dispatch(
                  updateSchoolData({
                    addressCheckInstitutionId,
                    updateSchoolDataDto:
                      schoolData satisfies UpdateSchoolDataDto,
                  })
                );
              }}
            >
              {isMobile ? t(`edit.finish.short.label`) : t(`edit.finish.label`)}
            </LoadingButton>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          ...(!editMode && {
            cursor: 'not-allowed',
            '*': {
              cursor: 'not-allowed',
            },
          }),
        }}
      >
        <Divider sx={{ opacity: 0, m: 1 }} />
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled
              loading={schoolDataLoading}
              label={t('name.label')}
              name={'name'}
              value={schoolData.name ?? ''}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('email.label')}
              name={'email'}
              value={schoolData.email ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  email: e.target.value,
                })
              }
              error={!!emailError}
              helperText={emailError?.errorMessages
                ?.map((i) => t('email.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PhoneNumberInput
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('phone.label')}
              name={'phone'}
              value={schoolData.phone ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  phone: e.target.value,
                })
              }
              error={!!phoneError}
              helperText={phoneError?.errorMessages
                ?.map((i) => t('phone.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('homepage.label')}
              name={'homepage'}
              value={schoolData.homepage ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  homepage: e.target.value,
                })
              }
              error={!!homePageError}
              helperText={homePageError?.errorMessages
                ?.map((i) => t('homepage.error.' + i))
                ?.join('. ')}
            />
          </Grid>
        </Grid>

        <Divider sx={{ m: 3 }} />

        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('street.label')}
              name={'street'}
              value={schoolData.street ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  street: e.target.value,
                })
              }
              error={!!streetError}
              helperText={streetError?.errorMessages
                ?.map((i) => t('street.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={2} xs={6}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('streetNumber.label')}
              name={'streetNumber'}
              value={schoolData.streetNumber ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  streetNumber: e.target.value,
                })
              }
              error={!!streetNumberError}
              helperText={streetNumberError?.errorMessages
                ?.map((i) => t('streetNumber.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={4} xs={6}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('streetAddition.label')}
              name={'streetAddition'}
              value={schoolData.streetAddition ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  streetAddition: e.target.value ?? '',
                })
              }
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('postalCode.label')}
              name={'postalCode'}
              value={schoolData.postalCode ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  postalCode: e.target.value,
                })
              }
              error={!!postalCodeError}
              helperText={postalCodeError?.errorMessages
                ?.map((i) => t('postalCode.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldWithLoading
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading}
              label={t('city.label')}
              name={'city'}
              value={schoolData.city ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  city: e.target.value,
                })
              }
              error={!!cityError}
              helperText={cityError?.errorMessages
                ?.map((i) => t('city.error.' + i))
                ?.join('. ')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <IsoCountrySelect
              fullWidth
              disabled={!editMode || editSchoolDataLoading}
              loading={schoolDataLoading || countriesLoading}
              label={t('country.label')}
              name={'country'}
              isoCountries={countries}
              value={schoolData.country ?? ''}
              onChange={(e) =>
                handleSchoolDataChange({
                  ...schoolData,
                  country: e.target.value as IsoCountryCodeEnum,
                })
              }
              error={!!countryError}
              helperText={countryError?.errorMessages
                ?.map((i) => t('country.error.' + i))
                ?.join('. ')}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
