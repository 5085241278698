/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { AddressCheckInstitutionLoginDto } from '../models/index';
import {
  AddressCheckInstitutionLoginDtoFromJSON,
  AddressCheckInstitutionLoginDtoToJSON,
} from '../models/index';

export interface AddressCheckInstitutionIdIsActiveRequest {
  addressCheckInstitutionId: string;
}

export interface LoginRequest {
  addressCheckInstitutionId: string;
  addressCheckInstitutionLoginDto?: AddressCheckInstitutionLoginDto;
}

/**
 *
 */
export class AddressCheckInstitutionLoginApi extends runtime.BaseAPI {
  /**
   */
  async addressCheckInstitutionIdIsActiveRaw(
    requestParameters: AddressCheckInstitutionIdIsActiveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling addressCheckInstitutionIdIsActive.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'adminAuth',
        []
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('publicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/validate/{addressCheckInstitutionId}`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async addressCheckInstitutionIdIsActive(
    requestParameters: AddressCheckInstitutionIdIsActiveRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<void> {
    await this.addressCheckInstitutionIdIsActiveRaw(
      requestParameters,
      initOverrides
    );
  }

  /**
   */
  async loginRaw(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<runtime.ApiResponse<string>> {
    if (
      requestParameters.addressCheckInstitutionId === null ||
      requestParameters.addressCheckInstitutionId === undefined
    ) {
      throw new runtime.RequiredError(
        'addressCheckInstitutionId',
        'Required parameter requestParameters.addressCheckInstitutionId was null or undefined when calling login.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (this.configuration && this.configuration.accessToken) {
      // oauth required
      headerParameters['Authorization'] = await this.configuration.accessToken(
        'adminAuth',
        []
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('publicAuth', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/public/v1/login/{addressCheckInstitutionId}`.replace(
          `{${'addressCheckInstitutionId'}}`,
          encodeURIComponent(
            String(requestParameters.addressCheckInstitutionId)
          )
        ),
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: AddressCheckInstitutionLoginDtoToJSON(
          requestParameters.addressCheckInstitutionLoginDto
        ),
      },
      initOverrides
    );

    if (this.isJsonMime(response.headers.get('content-type'))) {
      return new runtime.JSONApiResponse<string>(response);
    } else {
      return new runtime.TextApiResponse(response) as any;
    }
  }

  /**
   */
  async login(
    requestParameters: LoginRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction
  ): Promise<string> {
    const response = await this.loginRaw(requestParameters, initOverrides);
    return await response.value();
  }
}
