/* tslint:disable */
/* eslint-disable */
/**
 * AddressCheckPublic API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from '../runtime';
import type { IsoCountryCodeEnum } from './IsoCountryCodeEnum';
import {
  IsoCountryCodeEnumFromJSON,
  IsoCountryCodeEnumToJSON,
} from './IsoCountryCodeEnum';

/**
 *
 * @export
 * @interface ReadSchoolDataDto
 */
export interface ReadSchoolDataDto {
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  name: string;
  /**
   *
   * @type {IsoCountryCodeEnum}
   * @memberof ReadSchoolDataDto
   */
  country: IsoCountryCodeEnum;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  postalCode: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  city: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  street: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  streetNumber: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  streetAddition?: string | null;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  phone: string;
  /**
   *
   * @type {string}
   * @memberof ReadSchoolDataDto
   */
  homepage?: string | null;
}

/**
 * Check if a given object implements the ReadSchoolDataDto interface.
 */
export function instanceOfReadSchoolDataDto(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'name' in value;
  isInstance = isInstance && 'country' in value;
  isInstance = isInstance && 'postalCode' in value;
  isInstance = isInstance && 'city' in value;
  isInstance = isInstance && 'street' in value;
  isInstance = isInstance && 'streetNumber' in value;
  isInstance = isInstance && 'email' in value;
  isInstance = isInstance && 'phone' in value;

  return isInstance;
}

export function ReadSchoolDataDtoFromJSON(json: any): ReadSchoolDataDto {
  return ReadSchoolDataDtoFromJSONTyped(json, false);
}

export function ReadSchoolDataDtoFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ReadSchoolDataDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json['name'],
    country: IsoCountryCodeEnumFromJSON(json['country']),
    postalCode: json['postalCode'],
    city: json['city'],
    street: json['street'],
    streetNumber: json['streetNumber'],
    streetAddition: !exists(json, 'streetAddition')
      ? undefined
      : json['streetAddition'],
    email: json['email'],
    phone: json['phone'],
    homepage: !exists(json, 'homepage') ? undefined : json['homepage'],
  };
}

export function ReadSchoolDataDtoToJSON(value?: ReadSchoolDataDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    country: IsoCountryCodeEnumToJSON(value.country),
    postalCode: value.postalCode,
    city: value.city,
    street: value.street,
    streetNumber: value.streetNumber,
    streetAddition: value.streetAddition,
    email: value.email,
    phone: value.phone,
    homepage: value.homepage,
  };
}
